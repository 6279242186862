@import '../../../sass/mixins';
@import '../../../sass/animations';

.side-drawer {
    overflow: hidden;
    display: none;
    position: fixed;
    height: 26rem;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 900;
    box-shadow: 0 .1rem .1rem rgba(0, 0, 0, 0.4);
    background-color: rgba(0, 0, 0, .8);

    @supports(-webkit-backdrop-filter: blur(2px)) or (backdrop-filter: blur(2px)) {
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
    }

    box-sizing: border-box;
    transition: all 0.5s ease;

    &--opened {
        transform: translate(0);
    }

    &--closed {
        transform: translateY(-101%);
    }

    @include respond(tab-port) {
        display: block;
    }

    &__nav {
        height: 100%;
    }
}