@import "../../../sass/mixins";
@import "../../../sass/variables";
@import "../../../sass/animations";

.toolbar {
  height: 14rem;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  z-index: 1000;

  @supports(-webkit-backdrop-filter: blur(2px)) or (backdrop-filter: blur(2px)) {
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
  }

  &--sticky {
    position: fixed;
    height: 5rem;
    top: 0;
    left: 0;
    box-shadow: 0 .1rem .1rem rgba(0, 0, 0, 0.4);
    background-color: rgba($color-black, .8);
  }

  &--transparent {
    background-color: transparent;
    box-shadow: none;
    backdrop-filter: none;
    position: fixed;
    right: 0;
    height: auto;
  }

  &__nav {
    height: 100%;
  }

  &__link-home {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.desktop-only {
  display: initial;

  @include respond(tab-port) {
      display: none;
  }
}