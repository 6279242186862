@import '../../sass/variables';
@import '../../sass/mixins';
@import '../../sass/animations';

.language-pack {
    position: fixed;
    z-index: 1200;
    left: 0;
    top: 13.5rem;
    padding: 1rem 0;
    user-select: none;
    outline: transparent;
    -webkit-tap-highlight-color: transparent;

    transform: translateX(-9rem);
    transition: all .5s ease;

    &__toggler {
        cursor: pointer;
        width: 14rem;
        color: $color-white;
        font-size: 3rem;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        box-shadow: 0 .1rem .1rem rgba(0, 0, 0, 0.4);
        background-color: $color-background;

        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;

        @include respond(tab-land) {
            font-size: 3.4rem;
        }

        &-icon {
            transition: all .5s ease;
        }
    }

    &__down {
        display: block;
        padding: 0 1rem;
        margin: 0 -.1rem;
    }

    &__select {
        box-shadow: 0 .1rem .1rem rgba(0, 0, 0, 0.4);
        background-color: $color-background;

        font-size: 1.4rem;
        color: $color-white;
        border: none;
        padding: 1rem;
        cursor: pointer;
        display: block;
        text-align: center;
        width: 8rem;
        outline: none;
        border: 1px solid rgba($color-black, .8);

        @include respond(tab-land) {
            font-size: 1.7rem;
        }

        &:active,
        &:focus {
            border: 1px solid $color-white;
        }

        &:last-child {
            border-bottom-left-radius: .5rem;
            border-bottom-right-radius: .5rem;
        }
    }

    &--show {
        transform: translateX(0);
    }

    &--hide {
        transform: translateX(-9.5rem);
    }

    &--moveUp {
        top: 4.5rem;
    }

}