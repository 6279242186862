@import '../../sass/mixins';

.logo {
    height: 90%;
    margin-left: 1rem;

    @include respond(phone) {
        height: 80%;
    }

    &__image {
        max-height: 100%;
        max-width: 100%;

        &--phone {
            @include respond(phone) {
                display: none;
            }
        }
    }
}