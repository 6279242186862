.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 900;
  left: 0;
  top: 0;

  &--visible {
    background-color: rgba(0, 0, 0, 0.4);

    @supports(-webkit-backdrop-filter: blur(2px)) or (backdrop-filter: blur(2px)) {
      -webkit-backdrop-filter: blur(3px);
      backdrop-filter: blur(3px);
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}