@import '../sass/mixins';
@import '../sass/variables';

.toolbar-height {
  height: 14rem;
  width: 100%;

  position: relative;

  &--small {
    height: 5rem;

    position: absolute;
    top: 0;
    left: 0;
  }
}

.home-media {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 100px;
  background-color: $color-background;
}
