@import '../../../../sass/variables';
@import '../../../../sass/mixins';

.drawer-toggle__button {
    display: none;
    position: relative;
    height: 100%;
    width: 8rem;
    margin-right: 2.5rem;

    cursor: pointer;

    @include respond(tab-port) {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @include respond(phone) {
        margin-right: .5rem;
    }
}

.drawer-toggle__icon {
    position: relative;
    z-index: 1000;

    display: flex;
    align-items: center;
    justify-content: center;

    &,
    &::before,
    &::after {
        width: 4.5rem;
        height: 2px;
        background-color: $color-white;
        display: block;
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        left: 0;
        transition: all .3s;
    }

    &::before {
        top: -1.1rem;
    }

    &::after {
        top: 1.1rem;
    }

    &--active {
        position: fixed;
        right: .8rem;
        top: 3rem;
        display: block;
        z-index: 1000;
        background-color: transparent;

        &,
        &::before,
        &::after {
            @include respond(tab-port) {
                width: 3rem;
            }
        }

        &::before {
            top: -1rem;
            transform: rotate(-45deg);
        }

        &::after {
            top: -1rem;
            transform: rotate(-135deg);
        }

    }
}