@import '../../../../sass/variables';
@import '../../../../sass/mixins';
@import '../../../../sass/animations';

.navigation-item {
    color: rgba($color-white, 0.9);
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0 1rem;
    list-style: none;
    margin: 0 auto;

    white-space: nowrap;

    @include respond(tab-port) {
        padding: 0;
        width: 100%;
        height: auto;
    }

    &__link,
    &__link:link,
    &__link:visited {
        height: 100%;
        width: 100%;
        color: inherit;
        text-decoration: none;

        display: flex;
        align-items: center;

        @include respond(tab-port) {
            width: 100%;
            display: inline-block;
            text-align: start;
        }
    }

    &:hover &__link {
        cursor: pointer;
    }

    &--active {
        color: $color-white;
        text-shadow: 0px 0px 2px white;
    }


}