@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideAppear {
  0% {
    transform: translateY(-2rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide .slider-content__text__title {
  transition: all 1.2s ease;
  transform: translateY(-3rem);
  opacity: 0;
}

.slide.animateIn.previous .slider-content__text__title,
.slide.current .slider-content__text__title,
.slide.animateIn.next .slider-content__text__title {
  transform: translateX(0);
  transition-delay: 1.4s;
  opacity: 1;
}

.slide.animateOut .slider-content__text__title {
  -webkit-transition-delay: .3s;
  transition-delay: .3s;
}


@keyframes navArrowAnimationRight {
  0% {
    transform: translateX(0) translateY(-50%) rotate(45deg);
  }

  50% {
    transform: translateX(-1.5rem) translateY(-50%) rotate(45deg);
  }

  100% {
    transform: translateX(0) translateY(-50%) rotate(45deg);
  }
}

@keyframes navArrowAnimationLeft {
  0% {
    transform: translateX(0) translateY(-50%) rotate(225deg);
  }

  50% {
    transform: translateX(1.5rem) translateY(-50%) rotate(225deg);
  }

  100% {
    transform: translateX(0) translateY(-50%) rotate(225deg);
  }
}