@import '../../sass/variables';
@import '../../sass/mixins';

.section-contact {
    min-height: 100%;

    &__content {
        padding: 2rem 0;
        max-width: 120rem;
        margin: 0 auto;

        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.contact-details {
    text-align: center;
    letter-spacing: 2px;
    font-weight: 300;
    color: #ccc;
    font-size: 2rem;

    @include respond(phone) {
        font-size: 1.8rem;
    }

    a,
    a:link,
    a:visited {
        color: $color-white;
        text-decoration: none;
        transition: all .2s ease;
    }

    a:hover {
        text-decoration: underline;
    }

    &__row {
        line-height: 1.5;
        margin-bottom: 2rem;
    }

    &__info {
        margin-left: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
    }

    &__icon {
        font-size: 1.5rem;
    }

    &__name {
        margin-bottom: 2px;
    }
}

.contact-location {
    height: 100%;
    width: 100%;

    &__map {
        animation: appear 1s ease-in-out;
        position: relative;
        height: 42rem;
        width: 100%;
        overflow: hidden;
    }

    &__title {
        color: rgb(196, 196, 196);
        font-weight: 400;
        letter-spacing: .1rem;
        font-size: 1.8rem;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 4px;

        @include respond(phone) {
            font-size: 1.6rem;
        }
    }

    &__info {
        text-align: center;
        margin-bottom: 1rem;
        font-size: 1.3rem;
        color: #bbb;
    }
}