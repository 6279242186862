// COLORS
$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey: #777;
$color-grey-2: #999;
$color-grey-3: #aaa;
$color-grey-dark: #333;

$color-white: #f8f4f5;
$color-black: #000;
$color-dark: rgb(13, 13, 13);

$color-background: #141414;

// FONT
$default-font-size: 1.6rem;
$small-font-size: 1.3rem;
$title-font-size: 2.2rem;