@import '../../../sass/mixins';
@import '../../../sass/variables';

.navigation-list {
    display: flex;

    height: 100%;
    font-weight: 300;

    font-size: 1.4rem;
    text-transform: uppercase;

    @include respond(tab-port) {
        padding: 2rem 8rem;
        margin: 0 auto;
        font-size: 2rem;

        display: grid;
        grid-template-columns: auto auto;
        grid-auto-flow: dense;
        justify-content: space-evenly;
        align-items: center;
        grid-column-gap: 2rem;
    }

    @include respond(phone) {
        padding: 2rem;
    }
}