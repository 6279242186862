@mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// MEDIA QUERY MANAGER
/* 
0px - 600px: Phone
600px - 900px: Tablet portrait
900px - 1200px: Tablet landscape
[1200px - 1800px] is where our normal styles apply
1800px+: Big desktop

// @mixin respond-phone {
//     @media (max-width: 600px) {
//         @content;
//     }
// }

$breakpoint argument choices:
- phone
- tab-port
- tab-land
- big-desktop

1em = 16px
*/
@mixin respond($breakpoint) {
    @if $breakpoint==phone {
        @media only screen and (max-width: 37.5em) {
            //600px
            @content;
        }
    }

    @if $breakpoint==tab-port {
        @media only screen and (max-width: 56.25em) {
            //900px
            @content;
        }
    }

    @if $breakpoint==tab-land {
        @media only screen and (max-width: 75em) {
            //1200px
            @content;
        }
    }

    @if $breakpoint==big-desktop {
        @media only screen and (min-width: 112.5em) {
            //1800px
            @content;
        }
    }
}